/*  imported from index.less */

#meepshop { .meepshop-meep-ui__image-img-index__root {
  display: flex;
  width: 100%;

  &.meepshop-meep-ui__image-img-index__left {
    justify-content: flex-start;
  }

  &.meepshop-meep-ui__image-img-index__right {
    justify-content: flex-end;
  }

  &.meepshop-meep-ui__image-img-index__center {
    justify-content: center;
  }

  & > .meepshop-meep-ui__image-img-index__loading {
    overflow: hidden;
  }

  /* inline 元素會造成有一小條空白空間*/
  a div img {
    display: block;
  }
}

.meepshop-meep-ui__image-img-index__image {
  width: 100%;

  &.meepshop-meep-ui__image-img-index__background {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
}
 }
@font-family: "Noto Sans TC", "PingFang TC", "Microsoft JhengHei", "Helvetica Neue", "Helvetica", "source-han-sans-traditional", "Arial", "sans-serif";@font-family-no-number: "Noto Sans TC", "PingFang TC", "Microsoft JhengHei", "Helvetica Neue", "Helvetica", "source-han-sans-traditional", "Arial", "sans-serif";