/*  imported from placeholder.less  */

#meepshop { .meepshop-meep-ui__image-placeholder__root {
  display: flex;
  width: 100%;

  &.meepshop-meep-ui__image-placeholder__left {
    justify-content: flex-start;
  }

  &.meepshop-meep-ui__image-placeholder__right {
    justify-content: flex-end;
  }

  &.meepshop-meep-ui__image-placeholder__center {
    justify-content: center;
  }

  & > .meepshop-meep-ui__image-placeholder__loading {
    overflow: hidden;
  }
}

.meepshop-meep-ui__image-placeholder__placeholder {
  width: 100%;
  min-height: 240px;

  &.meepshop-meep-ui__image-placeholder__done {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #999999;
    font-size: 24px;
    transition: all 0.3s ease;
  }
}
 }
@font-family: "Noto Sans TC", "PingFang TC", "Microsoft JhengHei", "Helvetica Neue", "Helvetica", "source-han-sans-traditional", "Arial", "sans-serif";@font-family-no-number: "Noto Sans TC", "PingFang TC", "Microsoft JhengHei", "Helvetica Neue", "Helvetica", "source-han-sans-traditional", "Arial", "sans-serif";